import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Axios from 'axios';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from "react-router-dom";

// Axios.defaults.baseURL = "http://localhost:3001/";

// Axios.defaults.baseURL = 'https://www.rxfree4me.com:3002/'; /* rxfree4me test */

Axios.defaults.baseURL = 'https://www.rxfree4me.com:3001/'; /* rxfree4me live */


//11184400_218815
Axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
Axios.defaults.headers.post['Accept'] = "application/json";

ReactDOM.render(
  <React.StrictMode>
    {/* <Router basename={'/'}> */}
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
