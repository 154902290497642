import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Axios from 'axios';
import "./BasicInfoForm.css";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect } from 'react';
const filter = createFilterOptions();

const BasicInfo = (props) => {
	// const [value, setValue] = React.useState(null)
	// const handleDateChange = (newValue) => {
	// 	setValue(newValue);
	// };
	const [groupOptions, setOptions] = React.useState([]);
	const [brokerOptions, setBrokerOptions] = React.useState([]);
	
	const handleDateChange = (newValue) => {

		// console.log("/***********************************");
		// console.log(newValue)
		// console.log("/***********************************");

		// setValue(newValue);
		if(newValue)
		props.onInputchange( newValue );
	};

	/*
	useEffect(() => {
		// console.log("calllll use effect")
		// Axios.get('orders/getEmployerGroups').then(async (response) => {
		Axios.get('orders/employer-group-list').then(async (response) => {
			// console.log(response);

			var getEmployerGroupsData = await response.data.Result.employerGroups;
			// console.log(getEmployerGroupsData);
			var groupOption = [];
			await getEmployerGroupsData.map(( item, k )=>{
				groupOption.push({
					title: item.name,
					value: item._id
				})
			});
			await setOptions(groupOption);
		}).catch(error => {
			//this.setState( { loading: false } );
			console.log("error of react - catch section");
			console.log(error);
			console.log("error of react - catch section");
		}).finally(res => {
		});


		Axios.get('broker/list').then(async (response) => {
			// console.log("broker/list response=>>>>>>>>>>",response);

			var getBrokerData = await response.data.Result.data;
			// console.log(getBrokerData);
			var brokerOption = [];
			// brokerOption = [{
			// 	title: "item1",
			// 	value: "v1"
			// },{
			// 	title: "item2",
			// 	value: "v2"
			// },{
			// 	title: "item3",
			// 	value: "v3"
			// },]
			await getBrokerData.map(( item, k )=>{
				// console.log("item======>>>>>>",item)
				brokerOption.push({
					title: item.name,
					value: item._id
				})
			});
			await setBrokerOptions(brokerOption);
		}).catch(error => {
			//this.setState( { loading: false } );
			console.log("error of react - catch section");
			console.log(error);
			console.log("error of react - catch section");
		}).finally(res => {
		});
		
	}, []) */
	
	const formatPhoneNumber = (phoneNumber) => {
		const cleaned = phoneNumber.replace(/\D/g, '').slice(0,10);
		let formattedValue = cleaned;
		if (formattedValue.length > 3) 
			formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
		if (formattedValue.length > 7) 
			formattedValue = `${formattedValue.slice(0, 7)}-${formattedValue.slice(7)}`;
		return formattedValue;
	};
	
	return (
		<>
			<Box
				component="form"
				noValidate
				autoComplete="off"
				>
				<Grid container spacing={2}>
					<Grid item md={4} sm={6} xs={12} >
						<TextField
						error={(props.state.first_name_error) ? props.state.first_name_error : false}
						id="first-name"
						name="first_name"
						label="First Name"
						helperText={props.state.first_name_error ? "This field is required" : ""}
						fullWidth
						onInput = {(e) =>{
							e.target.value = e.target.value.slice(0,25);
						}}
						onBlur={(e) => {
							e.target.value = e.target.value.trim();
							props.onInputchange(e)
						}}
						onChange={props.onInputchange}
						value={props.state.first_name}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						id="last-name"
						name="last_name"
						label="Last Name"
						helperText=" "
						fullWidth
						onInput = {(e) =>{
							e.target.value = e.target.value.slice(0,25);
						}}
						onBlur={(e) => {
							e.target.value = e.target.value.trim();
							props.onInputchange(e)
						}}
						onChange={props.onInputchange}
						value={props.state.last_name}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						error={(props.state.email_error || props.state.email_validation_error ) ? true : false}
						name="email"
						id="email"
						label="Email"
						helperText={props.state.email_error ? "This field is required" : props.state.email_validation_error ? "Please enter a valid email address" : "" }
						onChange={props.onInputchange}
						value={props.state.email}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							inputProps={{
								type:"tel",
							}}
							fullWidth
							name="mobile"
							id="phone-number"
							label="Phone Number"
							helperText=" "
							onInput = {(e) =>{
								const newnumber = formatPhoneNumber(e.target.value);
								e.target.value = newnumber;
							}}
							onChange={props.onInputchange}
							value={props.state.mobile}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						name="street"
						id="Street-address"
						label="Street Address"
						helperText=" "
						onChange={props.onInputchange}
						value={props.state.street}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						name="apartment_number"
						id="apartment"
						label="Apartment/Suite Number"
						helperText=" "
						onChange={props.onInputchange}
						value={props.state.apartment_number}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						name="city"
						fullWidth
						id="city"
						label="City"
						helperText=" "
						onInput = {(e) =>{
							e.target.value = e.target.value.replace(/[^A-Za-z_\s]/g, '').slice(0,50);
						}}
						onBlur={(e) => {
							e.target.value = e.target.value.trim();
							props.onInputchange(e)
						}}
						
						onChange={props.onInputchange}
						value={props.state.city}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						select
						name="orderState"
						fullWidth
						id="state"
						label="State"
						helperText=" "
						onChange={props.onInputchange}
						value={props.state.orderState}
						>

							<MenuItem sx={{fontSize: 16}} key={1} value="Alabama">Alabama</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={2} value="Alaska">Alaska</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={3} value="Arizona">Arizona</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={4} value="Arkansas">Arkansas</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={5} value="American Samoa">American Samoa</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={6} value="California">California</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={7} value="Colorado">Colorado</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={8} value="Connecticut">Connecticut</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={9} value="Delaware">Delaware</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={10} value="District of Columbia">District of Columbia</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={11} value="Florida">Florida</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={12} value="Georgia">Georgia</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={13} value="Guam">Guam</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={14} value="Hawaii">Hawaii</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={15} value="Idaho">Idaho</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={16} value="Illinois">Illinois</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={17} value="Indiana">Indiana</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={18} value="Iowa">Iowa</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={19} value="Kansas">Kansas</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={20} value="Kentucky">Kentucky</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={21} value="Louisiana">Louisiana</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={22} value="Maine">Maine</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={23} value="Maryland">Maryland</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={24} value="Massachusetts">Massachusetts</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={25} value="Michigan">Michigan</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={26} value="Minnesota">Minnesota</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={27} value="Mississippi">Mississippi</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={28} value="Missouri">Missouri</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={29} value="Montana">Montana</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={30} value="Nebraska">Nebraska</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={31} value="Nevada">Nevada</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={32} value="New Hampshire">New Hampshire</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={33} value="New Jersey">New Jersey</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={34} value="New Mexico">New Mexico</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={35} value="New York">New York</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={36} value="North Carolina">North Carolina</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={37} value="North Dakota">North Dakota</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={38} value="Northern Mariana Islands">Northern Mariana Islands</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={39} value="Ohio">Ohio</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={40} value="Oklahoma">Oklahoma</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={41} value="Oregon">Oregon</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={42} value="Pennsylvania">Pennsylvania</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={43} value="Puerto Rico">Puerto Rico</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={44} value="Rhode Island">Rhode Island</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={45} value="South Carolina">South Carolina</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={46} value="South Dakota">South Dakota</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={47} value="Tennessee">Tennessee</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={48} value="Texas">Texas</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={49} value="Trust Territories">Trust Territories</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={50} value="Utah">Utah</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={51} value="Vermont">Vermont</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={52} value="Virginia">Virginia</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={53} value="Virgin Islands">Virgin Islands</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={54} value="Washington">Washington</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={55} value="West Virginia">West Virginia</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={56} value="Wisconsin">Wisconsin</MenuItem>
							<MenuItem sx={{fontSize: 16}} key={57} value="Wyoming">Wyoming</MenuItem>
							
						</TextField>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						name="zipcode"
						id="zip-code"
						label="Zip Code"
						helperText=" "
						onInput = {(e) =>{
							e.target.value = e.target.value.replace(/[^0-9\.]+/g, '');if(e.target.value && e.target.value !== "")e.target.value = e.target.value.toString().slice(0,6);
						}}
						// Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
						onChange={props.onInputchange}
						value={props.state.zipcode}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								label="Date of Birth"
								name="date_of_birth"
								// inputFormat="MM/dd/yyyy"
								value={props.state.date_of_birth}
								//value={value}
								onChange={handleDateChange}
								disableFuture
								// onChange={(value) => props.onInputchange(value)}
								renderInput={
									(params) => <TextField 
											{...params}
											error={(props.state.date_of_birth_error) ? true : false}
											sx={{width: '100%'}}
											helperText={(props.state.date_of_birth_error) ? "This field is required" : "" }
										/>
								}
							/>
						</LocalizationProvider>

						{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label="Date of Birth"
								value={props.state.date_of_birth}
								onChange={handleDateChange}
								renderInput={(params) => 
									<TextField 
									{...params}
									sx={{width: '100%'}}
									helperText=" "
									/>
								}
							/>
						</LocalizationProvider> */}
						
					</Grid>

					<Grid item md={4} sm={6} xs={12}>
						<TextField
						id="member-id"
						name="member_id"
						label="Member Id"
						helperText=" "
						fullWidth
						onInput = {(e) =>{
							e.target.value = e.target.value.slice(0,15);
						}}
						onBlur={(e) => {
							e.target.value = e.target.value.trim();
							props.onInputchange(e)
						}}
						onChange={props.onInputchange}
						value={props.state.member_id}
						/>
					</Grid>

					<Grid item md={4} sm={6} xs={12}>
						<TextField
						id="person_code"
						name="person_code"
						label="Person Code"
						helperText=" "
						fullWidth
						onInput = {(e) =>{
							e.target.value = e.target.value;
						}}
						onBlur={(e) => {
							e.target.value = e.target.value.trim();
							props.onInputchange(e)
						}}
						onChange={props.onInputchange}
						value={props.state.person_code}
						/>
					</Grid>

					
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						id="employer-group"
						name="employer_group"
						label="Employer Name"
						error={(props.state.employer_group_error) ? true : false}
						helperText={props.state.employer_group_error ? "This field is required" : ""}
						fullWidth
						onChange={props.onInputchange}
						value={props.state.employer_group}
						/>
					</Grid>

					{/* <Grid item md={4} sm={6} xs={12}> */}
						{/* <TextField
							fullWidth
							name="employer_group"
							id="employer-group"
							label="Employer Group"
							helperText=" "
							onChange={props.onInputchange}
							value={props.state.employer_group}
						/> */}

						{/* <Autocomplete
							// onInput = {(e) =>{
							// 	e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
							// }}
							// disablePortal={true}
							autoHighlight
							value={props.state.employer_group}
							onChange={(event, newValue) => {
								// console.log("===Autocomplete event 888888888888888888888888888888 ",event.target.id);
								// console.log("===Autocomplete newValue ",newValue);
								console.log("onChange event fired", event)
								if(newValue)
									if (typeof newValue === 'string') {
										console.log("===Autocomplete 1 if ",newValue);
										props.onInputchange(event,{title: newValue});
										
									} else if (newValue && newValue.inputValue) {
										console.log("===Autocomplete 2 if ",newValue);
										props.onInputchange(event,{title: newValue.inputValue, value: null});
										
									} else {
										console.log("===Autocomplete 3 if ",newValue);
										props.onInputchange(event, {title: newValue.title, value:newValue.value} );
									}
								else{
									event.target.id = "employer-group-clear"
									props.onInputchange(event, null );
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option.title);

								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										title: `Add "${inputValue}"`,
									});
								}

								return filtered;
							}}
							// selectOnFocus
							clearOnBlur={false}
							handleHomeEndKeys
							name="employer_group"
							id="employer-group"
							options={
								groupOptions
							}
							// ListboxComponent={(<><div role="listbox" id="listboxab"></div></>)}
							getOptionLabel={(option) => {
								// Value selected with enter, right from the input
								// console.log("option",option);
								if (typeof option === 'string') {
									return option;
								}
								// Add "xxx" option created dynamically
								if (option.inputValue) {
									return option.inputValue;
								}
								// Regular option
								return option.title;
							}}
							renderOption={(props, option) => <li name="autocomplete_group" {...props}>{option.title}</li>}
							// sx={{ width: 300 }}
							freeSolo
							renderInput={(params) => (
								<TextField {...params} label="Employer Group" />
							)}
						/>
						
					</Grid> */}


					<Grid item md={4} sm={6} xs={12}>
						{/* Broker/TPA */}

						<TextField
						id="broker_tpa"
						name="broker_tpa"
						label="Broker/TPA"
						error={(props.state.broker_tpa_error) ? true : false}
						helperText={props.state.broker_tpa_error ? "This field is required" : ""}
						fullWidth
						onChange={props.onInputchange}
						value={props.state.broker_tpa}
						/>
						
						{/* <Autocomplete
							
							autoHighlight
							value={props.state.broker_tpa}
							onChange={(event, newValue) => {
								// console.log("===Autocomplete event 888888888888888888888888888888 ",event.target.id);
								// console.log("===Autocomplete newValue ",newValue);
								console.log("onChange event fired", event)
								if(newValue)
									if (typeof newValue === 'string') {
										console.log("===Autocomplete 1 if ",newValue);
										props.onInputchange(event,{title: newValue});
									} else if (newValue && newValue.inputValue) { // when not exist in list
										console.log("===Autocomplete 2 if ",newValue);
										props.onInputchange(event,{title: newValue.inputValue, value: null});
									} else {
										console.log("===Autocomplete 3 if ",newValue);
										props.onInputchange(event, {title: newValue.title, value:newValue.value } );
									}
								else{
									event.target.id = "broker-tpa-clear"
									props.onInputchange(event, null );
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option.title);

								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										title: `Add "${inputValue}"`,
									});
								}

								return filtered;
							}}
							// selectOnFocus
							clearOnBlur={false}
							handleHomeEndKeys
							name="broker_tpa"
							id="broker_tpa"
							options={
								brokerOptions
							}
							// ListboxComponent={(<><div role="listbox" id="listboxab"></div></>)}
							getOptionLabel={
								(option) => {
									// Value selected with enter, right from the input
									// console.log("option",option);
									if (typeof option === 'string') {
										return option;
									}
									// Add "xxx" option created dynamically
									if (option.inputValue) {
										return option.inputValue;
									}
									// Regular option
									return option.title;
								}
							}

							renderOption={(props, option) => <li name="autocomplete_group" {...props}>{option.title}</li>}
							// sx={{ width: 300 }}
							freeSolo
							
							renderInput={(params) => (
								<TextField error={( props.state.broker_tpa_error ) ? props.state.broker_tpa_error : false}
								helperText={props.state.broker_tpa_error ? "This field is required" : ""} {...params} label="Broker/TPA" />
							)}
						/> */}
						
					</Grid>
					
					<Grid item md={4} sm={6} xs={12} >
						<TextField
							id="request_submitted_by"
							name="request_submitted_by"
							label="Request submitted by"
							fullWidth
							onInput = {(e) =>{
								e.target.value = e.target.value.slice(0,30);
							}}
							onBlur={(e) => {
								e.target.value = e.target.value.trim();
								props.onInputchange(e)
							}}
							onChange={props.onInputchange}
							value={props.state.request_submitted_by}
						/>
					</Grid>
					
				</Grid>
			</Box>
		</>
	);
};
export default BasicInfo;