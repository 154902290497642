import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const PrescriptionForm = (props) => {
	//const [value, setValue] = React.useState(new Date(''));
	//const [otherMedicationsFlag, setOtherMedicationsFlag] = React.useState(false);
	//const [drugAllergiesFlag, setDrugAllergiesFlag] = React.useState(false);

	// const handleChange = (newValue) => {
	// 	setValue(newValue);
	// };
	// const handleOtherMedicationsChange = () => {
	// 	const flag = !otherMedicationsFlag;
	// 	setOtherMedicationsFlag( flag );
	// };
	// const handleDrugAllergiesChange = () => {
	// 	const flag = !drugAllergiesFlag;
	// 	setDrugAllergiesFlag( flag );
	// };
	
	const Input = styled('input')({
		display: 'none',
	});
	// const [state] = React.useState({
	// 	terms: true
	// });
	//const { terms } = state;
	//const otherMedicationsDisplay = otherMedicationsFlag ? 'd-none' : '';
	//const drugAllergiesDisplay = drugAllergiesFlag ? 'd-none' : '';

	const LinkCustom = <a href='terms' target={"_blank"} >Terms and Conditions</a>;
	
	const formatPhoneNumber = (phoneNumber) => {
		const cleaned = phoneNumber.replace(/\D/g, '').slice(0,10);
		let formattedValue = cleaned;
		if (formattedValue.length > 3) 
			formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
		if (formattedValue.length > 7) 
			formattedValue = `${formattedValue.slice(0, 7)}-${formattedValue.slice(7)}`;
		return formattedValue;
	};
	
	return (
		<>
			<Box component="form" noValidate autoComplete="off" >
				<Grid container spacing={2}>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						error={props.state.drug_name_error ?? false}
						required
						name="drug_name"
						id="drug-name"
						label="Drug Name"
						helperText={props.state.drug_name_error ? "Drug name field is required" : ""}
						onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<FormControl variant="outlined" sx={{width: '100%', mb:3}}>
							<InputLabel error={props.state.upload_prescription_error} htmlFor="outlined-adornment-prescription">Upload Prescription</InputLabel>
							<OutlinedInput
								error={props.state.upload_prescription_error}
								id="outlined-adornment-prescription"
								// type={values.prescription ? 'text' : 'prescription'}
								value={props.state.upload_prescription.length ? `${props.state.upload_prescription.length} selected` : "0 selected"}
								//onChange={props.onInputchange}
								endAdornment={
									<InputAdornment position="end">
										<label htmlFor="icon-button-file">
												<Input name="upload_prescription" accept=".jpg,.jpeg,.png,.pdf" multiple id="icon-button-file" type="file" onChange={props.onInputchange} />
												<IconButton aria-label="upload file" component="span" >
													<FileUploadOutlinedIcon />
												</IconButton>
										</label>
									</InputAdornment>
								}
								label="Upload Prescription"

							/>
							<small className='Mui-error' style={{fontSize:"0.8571428571428571rem", color: props.state.upload_prescription_error ? "#d32f2f" : null}}>The Max file size limit is 5MB for each and only JPG, JPEG, PNG & PDF are allowed. You can only upload a maximum of 5 files.</small>
						</FormControl>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						name="physician_name"
						id="physician-name"
						label="Physician Name"
						helperText="If you don't have a copy of your Rx, RxFree4Me will contact your physician on your behalf."
						onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						onInput = {(e) =>{
							const newnumber = formatPhoneNumber(e.target.value);
							e.target.value = newnumber;
							
							// e.target.value = e.target.value.replace(/[^0-9\.]+/g, '');if(e.target.value && e.target.value !== "")e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10);
						}}
						
						name="physician_number"
						id="physician-phone-number"
						label="Physician Phone Number"
						helperText=" "
						
						onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						fullWidth
						onInput = {(e) =>{
							const newnumber = formatPhoneNumber(e.target.value);
							e.target.value = newnumber;

							// e.target.value = e.target.value.replace(/[^0-9\.]+/g, '');if(e.target.value && e.target.value !== "")e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10);
						}}
						name="physician_fax_number"
						id="physician-fax-number"
						label="Physician Fax Number"
						helperText=" "
						onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							fullWidth
							name="diagnosis"
							id="diagnosis"
							label="Diagnosis"
							helperText=" "
							onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							// disabled={drugAllergiesFlag}
							fullWidth
							name="drug_allergies_text"
							id="drug-allergies"
							label="Drug Allergies"
							helperText=" "
							onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						// disabled={otherMedicationsDisplay}
						fullWidth
						name="other_medications_text"
						id="other-medications"
						label="Other Medications"
						helperText=" "
						onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
						// disabled={otherMedicationsDisplay}
						fullWidth
						name="patient_notes"
						id="patient_notes"
						label="Notes"
						helperText=" "
						onChange={props.onInputchange}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						
						<Checkbox checked={props.state.terms} 
						 	onChange={props.onInputchange} name="terms" 
							style={props.state.terms_error && !props.state.terms ? { color: "red" } : null }
						/>

						{/* I have read and agree to RxFree4Me's  {LinkCustom}, including that the medication may come from Canada. */}
						I have read and agree to RxFree4Me's  {LinkCustom}.
						
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
export default PrescriptionForm;