import * as React from 'react';
import './TermsAndConditions.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinkMui from '@mui/material/Link';
import { Fragment } from 'react';

class TermsAndConditions extends React.Component {

	render() {
		const TermsAndConditions = [
			{title: "Consent to Receive Messages", description: "By opting in, you agree to receive promotional and informational text messages from RxFree4Me, including but not limited to offers, updates, and other marketing communications.", key: 0},
			{title: "Frequency of Messages", description: "Message frequency may vary. You understand that message rates may apply depending on your mobile carrier.", key: 1},
			{title: "Opting Out", description: "You can opt out at any time by replying STOP to any message received. Once you opt out, you will no longer receive marketing texts from RxFree4Me.", key: 2},
			{title: "Message and Data Rates", description: "Standard message and data rates may apply depending on your mobile carrier. RxFree4Me is not responsible for any messaging fees incurred by participating in this service.", key: 3},
			{title: "Privacy and Data Security", description: "Your information will be kept confidential and will not be sold, shared, or disclosed to third parties, except as required by law or necessary to provide our services.", key: 4},
			{title: "No Guarantee of Availability", description: "RxFree4Me is not responsible for any delays or failures in message delivery due to carrier issues or technical limitations.", key: 5},
			{title: "Changes to Terms", description: "RxFree4Me reserves the right to update these Terms and Conditions at any time. Any changes will be effective immediately upon posting.", key: 6},
			{title: "Contact Information", description: <>If you have any questions, you can contact us at <LinkMui href='tel:8447943733' underline='none'> (844) 794-3733</LinkMui> or email us on <LinkMui href='mailto:info@RxFree4me.com' underline='none'>info@RxFree4me.com</LinkMui></>, key: 7},
		];
		const PrivacyPolicy = [
			{description: <>- Name, phone number, and any information provided during sign-up.<br/>- Communication preferences and any interactions with our text messaging services.</>, title: "Information We Collect", key: 0},
			{description: <>
				- To send promotional and informational messages.<br/>- To improve our services and customer experience.<br/>- To comply with legal obligations or enforce our terms.
			</>, title: "How We Use Your Information", key: 1},
			{description: "We take appropriate security measures to protect your information from unauthorized access, disclosure, or misuse.", title: "Data Security", key: 2},
			{description: "RxFree4Me will **never sell, share, or rent** your personal information to third parties for marketing purposes.", title: "No Sale or Sharing of Information", key: 3},
			{description: <>
			- You can opt out of receiving messages at any time by replying STOP.<br/>- You can request access to or deletion of your personal data by contacting us at <LinkMui href='tel:8447943733' underline='none'> (844) 794-3733</LinkMui> or email us on <LinkMui href='mailto:info@RxFree4me.com' underline='none'>info@RxFree4me.com</LinkMui>.
			</>, title: "Your Rights and Choices", key: 4},
			{description: "We may update this Privacy Policy as needed. Any changes will be posted with the updated effective date.", title: "Changes to This Policy", key: 5},
		];
		return (
			<Box sx={{py: 5}} id="terms-of-use">
				<Container maxWidth="lg">
					<Grid container>
						<Grid item md={12} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
							<Typography
								variant="h2"
								component="h1"             
								style={{color: "rgb(0, 31, 197)"}}
							>
								RxFree4Me Terms and Conditions
							</Typography>
							<Typography
								component="p"
								sx={{mb: 4}}
							>
								Effective Date: January 1, 2023
							</Typography>
							
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
								By providing your consent to receive text messages from RxFree4Me, you agree to the following Terms and Conditions:
							</Typography>
							<ol type="1">
								{TermsAndConditions.map(question => {
									return (
										<Fragment key={"term-"+question.key}>
											<Typography variant="body1" component="li" style={{color: "#2168bd", fontWeight: '600'}} sx={{mb: 0}} >{question.title}</Typography> 
											<Typography variant="body2" component="p" sx={{mb: 1}} >{question.description}</Typography>
										</Fragment>
									);
								})}
							</ol>
						</Grid>

						<Grid item md={12} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
							<Typography
								variant="h2"
								component="h1"             
								style={{color: "rgb(0, 31, 197)"}}
							>
								RxFree4Me Privacy Policy
							</Typography>
							<Typography
								component="p"
								sx={{mb: 4}}
							>
								Effective Date: January 1, 2023
							</Typography>
							
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
								RxFree4Me respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information.
							</Typography>
							<ol type="1">
								{PrivacyPolicy.map(question => {
									return (
										<Fragment key={"privecy-" + question.key}>
											<Typography variant="body1" component="li" style={{color: "#2168bd", fontWeight: '600'}} sx={{mb: 0}} >{question.title}</Typography> 
											<Typography variant="body2" component="p" sx={{mb: 1}} >{question.description}</Typography>
										</Fragment>
									);
								})}
							</ol>

							<Typography variant="body2" component="p" sx={{mb: 1}} >
								By using our services, you acknowledge and agree to the terms of this Privacy Policy. If you have any concerns, please contact us at <LinkMui href='tel:8447943733' underline='none'> (844) 794-3733</LinkMui> or email us on <LinkMui href='mailto:info@RxFree4me.com' underline='none'>info@RxFree4me.com</LinkMui>.
							</Typography>
							
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	}
}
export default TermsAndConditions;